











import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  components: {
    HeaderBar: () => import('@/components/HeaderBar/index.vue'),
    CalendarHeaderBar: () => import('@/components/Calendar/HeaderBar/index.vue'),
  },
})
export default class MainHeader extends Vue {
  @Prop({
    required: false,
    type: Boolean,
  }) isCalendarHeader!: boolean
}
